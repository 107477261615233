import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useClickOutside } from '../../hooks/useClickOutside';

const hide = () => {
  document.body.classList.remove('right-bar-enabled');
};

export const RightSideBar = ({ title = 'Right Sidebar', children }) => {
  const rightBarNodeRef = useRef(null);
  useClickOutside(rightBarNodeRef, hide);

  const handleClose = useCallback(e => {
    e.preventDefault();
    hide();
  }, []);

  return (
    <>
      <div className="right-bar" ref={rightBarNodeRef}>
        <div className="rightbar-title">
          <Link
            to="#"
            className="right-bar-toggle float-right"
            onClick={handleClose}
          >
            <i className="fe-x noti-icon" />
          </Link>
          <h4 className="m-0 text-white">{title}</h4>
        </div>
        <PerfectScrollbar>{children}</PerfectScrollbar>
      </div>
      <div className="rightbar-overlay" />
    </>
  );
};
